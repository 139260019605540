globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"1caac6de6012aab09a9ce43ab56c73207f578666"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://49e6947a6d31d385e461f32a37ab7313@o4505840189046784.ingest.us.sentry.io/4505908884406272',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/api\.knapsack\.cloud/],
  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [],
  ignoreErrors: [
    /**
     * "This error means that ResizeObserver was not able to deliver all observations within a single animation frame. It is benign (your site will not break). " ~ https://stackoverflow.com/a/50387233
     */
    'ResizeObserver loop limit exceeded',
    'Login required',
  ],
});
